
import useHeight from '../../utils/height';
import { StoreButton } from '../Buttons';
import { AppleIcon, PlayStoreIcon } from '../Icons';
import { ButtonBlack, ButtonGrey } from '../NewButtons';
import './paymentModal.css';



export const PaymentModal1 = ({ onClose, onClick, loading, currentProduct }) => {

    const { height } = useHeight()
    const PRICE = currentProduct?.price
    let isLive = currentProduct?.type == 'live'
    const isExpired = currentProduct?.isExpired
    const handleAppleClick = () => {
        let storeLink = 'https://apps.apple.com/cl/app/mint/id6467195856';
        let appDeepLink = 'mintapp://';
        setTimeout(() => {
            if (window.matchMedia('(display-mode: standalone)').matches || navigator.standalone) {
                window.location.href = appDeepLink;
            } else {
                window.location.href = storeLink;
            }
        }, 500);
    }

    const handleAndroidClick = () => {
        let storeLink = 'https://play.google.com/store/apps/details?id=info.mintapp';
        let appDeepLink = 'mintapp://';
        setTimeout(() => {
            if (window.matchMedia('(display-mode: standalone)').matches || navigator.standalone) {
                window.location.href = appDeepLink;
            } else {
                window.location.href = storeLink;
            }
        }, 500);
    }


    const handleClose = (e) => {
        if (onClose && e.target === document.getElementById('app-modal')) {
            onClose();
        }
    };

    return (
        <div className='app-modal-background font-interRegular' style={{ height, }} id='app-modal' onClick={handleClose}>
            <div style={{ backgroundColor: 'white', }} className='app-modal-container p-6 px-10  relative'>
                {isExpired && <>
                    <div className='text-[14px] mt-4'>
                        {isLive ? 'Está transmisión ya terminó' : 'Esta preventa ya expiró'}
                    </div>
                    <br />
                    <div className='mt-4' />
                    <ButtonBlack loading={loading} label={'Aceptar'} onClick={onClose} />
                    <br />
                </>}
                {!isExpired &&
                    <>
                        {currentProduct.price == '0' ? <>
                            <br />
                            <div style={{ lineHeight: 1.2 }} className='text-[17px] leading-none text-left mt-3'>
                                {'Accede a este contenido gratuitamente en MINT'}
                            </div>
                            <br />
                            <div className="flex justify-around my-8">
                                <StoreButton label="App Store" dark IconComponent={<AppleIcon size={25} color="white" />} onClick={handleAppleClick} />
                                <StoreButton label="Google Play" dark IconComponent={<PlayStoreIcon size={20} />} onClick={handleAndroidClick} />
                            </div>
                        </>
                            : <>
                                <div style={{ lineHeight: 1.2 }} className='text-[17px] leading-none text-left mt-3'>
                                    {currentProduct?.type == 'live' ? 'Compra una entrada para acceder a este concierto por' : 'Escucha esta canción durante un mes por'} <b>{PRICE}</b>
                                </div>
                                <div className='text-[10px] mt-4'>
                                    {currentProduct?.type == 'live' ? '' : 'Puedes escucharla cuantas veces quieras durante 30 días'}
                                </div>
                                <br />
                                <div className='mt-2' />
                                <ButtonBlack loading={loading} label={'Continuar'} onClick={onClick} />
                                <br />
                            </>}
                    </>
                }

            </div>
        </div>
    );
}
