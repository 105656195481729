import { useEffect, useState } from 'react';

import { Input, InputGrey, } from './NewInputs';
import { ButtonGrey } from './NewButtons';
import { str_not_empty } from '../utils';
import useHeight from '../utils/height';

import './CardDataModal.css';
import Validations from '../validations';


function CardDataModal({ handleClose, handleContinue, setName, loading }) {
  const handleOnClose = (e) => {
    if (handleClose && e.target === document.getElementById("card-modal")) {
      handleClose()
    }
  }
  return (
    <div className='modal-background h-screen' onClick={handleOnClose} id="card-modal">
      <div className='modal-container bg-white rounded-3xl mx-10'>
        <div className='modal-content p-6 text-sm'>
          <div className='mb-5 font-bold'>Ingresa nombre y apellido <u>como figura en la tarjeta</u> que vas a registrar</div>
          <Input onChange={(e) => setName(e.target.value)} className="mb-5 w-full" placeholder="Nombre y apellido" />
          <ButtonGrey className="w-full" loading={loading} onClick={handleContinue} label="Continuar" />
        </div>
      </div>
    </div>
  );
}

export default CardDataModal;


const initialErrorsState = {
  name: '',
  email: '',
  password: '',
  phone: '',
  reEmail: '',
  rePhone: '',
}

function hasErrors(errors) {
  return (
    errors.password !== '' || errors.email !== ''
  )
}
// errors.reEmail !== '' || errors.rePhone !== ''

export function UserDataModal({ onClose, onContinue, onReset, loading }) {
  const { height } = useHeight()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  // const [reEmail, setReEmail] = useState('')
  const [phone, setPhone] = useState('')
  // const [rePhone, setRePhone] = useState('')
  const [phoneCountry, setPhoneCountry] = useState("+56")
  // const [rePhoneCountry, setRePhoneCountry] = useState("+56")
  const [errors, setErrors] = useState(initialErrorsState)

  const [isLoginMood, setLoginMode] = useState(true)
  const [isResetMode, setResetMood] = useState(false)

  const handleClose = (e) => {
    if (onClose && e.target === document.getElementById('card-modal')) {
      onClose();
    }
  };

  useEffect(() => {
    setResetMood(false)
  }, [])

  const handleContinue = async () => {
    const err = { ...initialErrorsState }

    const _phone = phoneCountry + phone.trim()
    const _email = email.trim()
    const _name = name.trim()
    const _password = password.trim()

    if (!str_not_empty(_password)) {
      err.password = 'Ingresa una clave'
    }
    if (password.length < 6 && str_not_empty(_password)) {
      err.password = 'Clave debe tener al menos 6 caracteres'
    }
    if (!Validations.validateEmail(_email)) {
      err.email = 'Correo no es válido'
    }
    if (!str_not_empty(_email)) {
      err.email = 'El correo es requerido'
    }
    setErrors(err)
    if (hasErrors(err)) {
      return
    }
    onContinue(_email, _password, isLoginMood)
  }

  const handleReset = async () => {
    const err = { ...initialErrorsState }

    const _email = email.trim()

    if (!str_not_empty(_email)) {
      err.email = 'El correo es requerido'
    }
    if (!Validations.validateEmail(_email)) {
      err.email = 'Correo no es válido'
    }

    setErrors(err)
    if (hasErrors(err)) {
      return
    }
    onReset(_email)
  }

  const handleWhatsapp = () => {
    const text = encodeURIComponent("Hola! Estoy experimentando problemas técnicos.")
    window.open(`https://wa.me/+923414106139?text=${text}`, '_blank');
  }

  return (
    <div className='modal-background z-[30000]' style={{ height }} onClick={handleClose} id="card-modal">
      <div className='modal-container bg-white rounded-3xl mx-10 font-interRegular'>
        {!isResetMode && <div className='modal-content p-6 text-sm'>
          <div>
            {!isLoginMood ? <div className='font-interBold text-2xl'> Inicia Sesión</div>
              : <div className='font-interBold text-2xl'>Crea una cuenta</div>}
            <div className='text-[11px] my-3 leading-tight'>
              <div>Luego de realizar la compra deberás descargar la App MINT para poder reproducir tu contenido</div>
            </div>
          </div>
          <div className='flex flex-col my-6'>
            <InputGrey placeholder="Correo electrónico" value={email} onChange={e => setEmail(e.target.value.toLowerCase())} />
            <ErrorMessage value={errors['email']} />
            <InputGrey type='password' placeholder="Ingresa clave" value={password} onChange={e => setPassword(e.target.value)} />
            <ErrorMessage value={errors['password']} />
            {!isLoginMood && <div onClick={() => setResetMood(true)} className='cursor-pointer text-right text-[12px] ' style={{ textDecorationLine: 'underline' }}>Olvidaste tu clave?</div>}
          </div>
          <ButtonGrey loading={loading} onClick={handleContinue}
            label="Continuar"
          />
          <SessionTexts
            isLoginMood={isLoginMood}
            onPress={() => setLoginMode(!isLoginMood)}
          />
          <div onClick={handleWhatsapp} className='text-center italic mt-4 underline cursor-pointer'>Ayuda</div>
        </div>}

        {isResetMode && <div className='modal-content p-6 text-sm'>
          <div>
            <div className='font-interBold text-2xl'>Recuperar Clave</div>
            <div className='text-[11px] my-3 leading-tight'>
              <div style={{ width: '100%' }}>Por favor ingresa tu email para poder recuperar tu clave. Un enlace de recuperación será enviado a tu correo.</div>
            </div>
          </div>
          <div className='flex flex-col my-6'>
            <InputGrey placeholder="Correo electrónico" value={email} onChange={e => setEmail(e.target.value.toLowerCase())} />
            <ErrorMessage value={errors['email']} />
          </div>
          <div className='mt-6' />
          <ButtonGrey loading={loading} onClick={handleReset} label="Recuperar Clave" />
          <SessionTexts
            isLoginMood
            onPress={() => {
              setResetMood(false)
              setLoginMode(false)
            }}
          />
          <div onClick={handleWhatsapp} className='text-center italic mt-4 underline cursor-pointer'>Ayuda</div>
        </div>}
      </div>
    </div>
  )
}

const ErrorMessage = ({ value }) => (
  <div className='text-left text-red-400 w-full my-1'>{value}</div>
)

function firstCap(value) {
  let newValue = ''
  const split = value.split(' ')

  const firstUpper = split.map(uppercase)
  for (let i = 0; i < firstUpper.length; i++) {
    newValue += firstUpper[i]
    if (i < firstUpper.length - 1) {
      newValue += ' '
    }
  }

  return newValue
}

function SessionTexts({ isLoginMood, onPress }) {
  return (
    <div onClick={onPress} style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
      className='mt-2 cursor-pointer'>
      <div style={{ fontSize: 10 }}>{isLoginMood ? 'Ya tienes una cuenta?' : 'No tienes una cuenta?'}</div>
      <div style={{ width: 4 }} />
      {/* <div style={{ fontSize: 10, textDecorationLine: 'underline', fontWeight: 'bold' }}>{isLoginMood ? 'Ingresa correo y clave' : 'Inicia Sesión'}</div> */}
      <div style={{ fontSize: 10, textDecorationLine: 'underline', fontWeight: 'bold' }}>{isLoginMood ? 'Inicia Sesión' : 'Crea una cuenta'}</div>
    </div>
  )
}



function uppercase(value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}